const config = {
    appId: 'myapp',
    appName: 'Gabi Documents Manager',

    firebase: GABI_ENV === 'production' ? {
        apiKey: 'AIzaSyBH4cJB9-zcLftEAmgVU5EyweyXZMrC_To',
        authDomain: 'gsc-documents-manager-prod.firebaseapp.com',
        projectId: 'gsc-documents-manager-prod',
        storageBucket: 'gsc-documents-manager-prod.appspot.com',
        messagingSenderId: '654561186294',
        appId: '1:654561186294:web:6c8c5f1a5b09511735b7d7'
    } : GABI_ENV === 'acceptance' ? {
        apiKey: 'AIzaSyCJjaoOU429n1F8vNEhf22KCzNMQm6XJsQ',
        authDomain: 'gsc-documents-manager-ac-a1ca0.firebaseapp.com',
        projectId: 'gsc-documents-manager-ac-a1ca0',
        storageBucket: 'gsc-documents-manager-ac-a1ca0.appspot.com',
        messagingSenderId: '225380950982',
        appId: '1:225380950982:web:236b516832fba4fbac08c7',
    } : GABI_ENV === 'alpha' ? {
        apiKey: 'AIzaSyAVtfAhj61Ald37x-acRD_PB_eEEhl5xe4',
        authDomain: 'gsc-documents-manager-alpha.firebaseapp.com',
        projectId: 'gsc-documents-manager-alpha',
        storageBucket: 'gsc-documents-manager-alpha.appspot.com',
        messagingSenderId: '803228606731',
        appId: '1:803228606731:web:d7d4b012fc9c56659a420e',
        measurementId: 'G-SE6GSQCJ1V'
    } : {
        apiKey: 'AIzaSyDFzjliF3b2v2UfFb0X57wcfoh5P1poHE8',
        authDomain: 'gsc-documents-manager.firebaseapp.com',
        projectId: 'gsc-documents-manager',
        storageBucket: 'gsc-documents-manager.appspot.com',
        messagingSenderId: '1011855378115',
        appId: '1:1011855378115:web:4d93307ba3549cb3f28476',
    },

    gapi: GABI_ENV === 'production' ? {
        clientId: '654561186294-ra7ilkfk35o9s7a06uooi92h5bmjdsk7.apps.googleusercontent.com',
        clientSecret: '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',
        apiKey: 'AIzaSyBH4cJB9-zcLftEAmgVU5EyweyXZMrC_To',
        scopes: 'https://www.googleapis.com/auth/drive',
        discoveryDocs: 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    } : GABI_ENV === 'acceptance' ? {
        clientId: '225380950982-8c24jvh6qi6149rm4gjogfc4n812hao8.apps.googleusercontent.com',
        clientSecret: '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',
        apiKey: 'AIzaSyCJjaoOU429n1F8vNEhf22KCzNMQm6XJsQ',
        scopes: 'https://www.googleapis.com/auth/drive',
        discoveryDocs: 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    } : GABI_ENV === 'alpha' ? {
        clientId: '803228606731-vcgj9ujv99j8h39bdlmatpi7i431e56v.apps.googleusercontent.com',
        clientSecret: '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',
        apiKey: 'AIzaSyC7XFihGoL8P5SUWkJx54h6xeH2-Zo_VwE',
        scopes: 'https://www.googleapis.com/auth/drive',
        discoveryDocs: 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    } : {
        clientId: '1011855378115-o619va088uhna9av495bmhujueo108j1.apps.googleusercontent.com',
        clientSecret: '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',
        apiKey: 'AIzaSyDFzjliF3b2v2UfFb0X57wcfoh5P1poHE8',
        scopes: 'https://www.googleapis.com/auth/drive',
        discoveryDocs: 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    },
    
    feedbackAlerts: {
        lifetime: 10, // Time for a feedback message to be displayed (in seconds)
    },
    
    dateFormats: {
        time: 'HH:mm',
        fullTime: 'HH:mm:ss',
        date: 'dd/MM/yyyy',
        dateTime: 'dd/MM/yyyy HH:mm',
        dateTimeDay: 'eeee dd/MM/yyyy HH:mm',
        dateTimeSeconds: 'dd/MM/yyyy HH:mm:ss',
    },
};

export default config;
